<script>
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    lengthSupportList: {
      type: Array,
      default: () => [10, 13, 36]
    }
  },
  data() {
    return {
      code: "",
      finalCode: "",
      fromScanner: false,
      reading: false
    };
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyboardListener);
  },
  unmounted() {
    document.removeEventListener("keydown", this.documentKeyboardListener);
  },

  methods: {
    documentKeyboardListener(e) {
      if (e.target.nodeName !== 'BODY') return;
      if (e.code === "Enter") {
        if (this.reading && this.lengthSupportList.includes(this.code.length)) {
          if (this.onSuccess)
            this.onSuccess(this.code);

          this.finalCode = this.code;
          this.code = "";
          this.fromScanner = true;
        }
      } else {
        this.code += e.key; //while this is not an 'enter' it stores the every key
      }

      //run a timeout of 200ms at the first read and clear everything
      if (!this.reading) {
        this.reading = true;

        setTimeout(() => {
          this.code = "";
          this.reading = false;
          this.fromScanner = false;
        }, 500); //200 works fine for me but you can adjust it
      }
    },
  },
};
</script>
