<template>
  <oib-form
    :form_step_validation_list="form_step_validation_list"
    title="Leerlingvolgsysteem"
    @submit="submit"
    ref="form">
    <div v-if="!has_role(['SYSTEM_ADMIN', 'SCHOOL_ADMIN'])">
      <p>Je hebt geen permissies om deze taak uit te voeren. Vraag de schoolbeheerder om het leerlingvolgsysteem te koppelen.</p>
    </div>
    <div v-if="has_role(['SYSTEM_ADMIN', 'SCHOOL_ADMIN'])">
      <oib-input-multi-select-descriptive
        description="Welk leerlingvolgsysteem gebruikt jullie school?"
        :allow_multi_select="false"
        :required="true"
        :options="lvs_systems"
        @select="x => set_lvs_system(x[0])"
      />
    </div>
    <div v-if="has_role(['SYSTEM_ADMIN', 'SCHOOL_ADMIN'])">
      <oib-input-text
      label="Autorisatie sleutel"
      description="Activeer de koppeling via het leerlingvolgsysteem en genereer een autorisatie sleutel."
      placeholder="93yrtiht3957cn"
      :required="true"
      :error_msg="error_msg"
      @input="set_authorization_key($event.target.value)"
      >
        <template v-slot:hint>
          <p v-if="lvs_system_dict_server[lvs_system] == 'PARNASSYS'" style="font-size: 0.8em"><a href="https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding/#autorisatie_sleutel_genereren_parnassys" target="_blank">* Bekijk de ParnasSys handleiding</a></p>
          <p v-if="lvs_system_dict_server[lvs_system] == 'ESIS'" style="font-size: 0.8em"><a href="https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding/#autorisatie_sleutel_genereren_esis" target="_blank">* Bekijk de ESIS handleiding</a></p>
          <p v-if="lvs_system_dict_server[lvs_system] == 'MAGISTER'" style="font-size: 0.8em">Let op: Magister heeft onze koppeling nog niet geactiveerd.</p>
          <p v-if="lvs_system_dict_server[lvs_system] == 'SOMTODAY'" style="font-size: 0.8em">Let op: Deze koppeling is nieuw. Mocht de koppeling niet werken, stuur dan een email naar stijn@onderwijsinbeeld.nl.</p>
        </template>
      </oib-input-text>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"

import {has_role} from "../store/utils"

export default {
  name: "OibFormLvs",
  props: ['error_msg'],
  components: {
    OibForm,
    OibInputText,
    OibInputMultiSelectDescriptive
  },
  data() {
    return {
      form_step_validation_list: [false, false],
      lvs_systems: [
        {tag: 'ParnasSys', details: ''},
        {tag: 'ESIS', details: ''},
        {tag: 'SomToday', details: ''},
        {tag: 'Magister', details: ''},
      ],
      lvs_system_dict_server: ['PARNASSYS', 'ESIS', 'SOMTODAY', "MAGISTER"],
      lvs_system: null,
      authorization_key: "",
    }
  },
  methods: {
    // Import
    has_role,
  
    // Local  
    set_lvs_system(system) {
      this.lvs_system = system
      setTimeout(this.$refs.form.next_step, 500)
    },
    set_authorization_key(key) {
      this.authorization_key = key
      this.val_step_2()
    },
    async val_step_2() {

      if (this.lvs_system == null || this.authorization_key.length < 7) {
        this.form_step_validation_list[1] = false
        return
      }

      this.form_step_validation_list[1] = true
    },
    async submit() {

      // Publish submit event
      this.$emit("submit", {
        'lvs_type': this.lvs_system_dict_server[this.lvs_system],
        'authorization_key': this.authorization_key.trim()
      })
    }
  }
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>